@use '@angular/material' as mat;

// The default fonts are too tiny
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Roboto',
  $headline: mat.define-typography-level(32px, 48px, 500),
  $body-1: mat.define-typography-level(16px, 24px, 400)
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ide-app-primary: mat.define-palette(mat.$yellow-palette);
$ide-app-accent:  mat.define-palette(mat.$lime-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ide-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ide-app-theme:   mat.define-light-theme($ide-app-primary, $ide-app-accent, $ide-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($ide-app-theme);

/* Bootstraps reboot re-introduces underlines for links. We need to
   remove them manually for the moment. */
a, a:hover {
    text-decoration: none;
}
a.mat-menu-item, a.mat-menu-item:hover {
    text-decoration: none;
}

app-toolbar {
    /* Allows to align the following items on the right */
    .fill-space {
        flex: 1 1 auto;
    }

    /* Angular material defaults to a ridiculous height */
    .mat-toolbar-row, .mat-toolbar-single-row {
        height: $toolbar-height;
    }
}

/* Hack angulars lists do use a flex box to ease filling */
mat-nav-list#main-nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0px;

    .fill {
        flex: 1 1 auto;
    }

    /* Active links must be visually distinguishable from all the other links  */
    .router-link-active {
        background-color: mat.get-color-from-palette($ide-app-primary);
    }

    /* Don't break in menu items */
    .mat-list-item {
        white-space: nowrap;

        /* Ensure that the actual name of the target is never wrapped, has a sensible
           minimum width but grows to fill all available space. */
        .nav-target {
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 16ch;
            flex-grow: 1;
        }
    }
}

.mat-drawer-container {
    background-color: mat.get-color-from-palette($ide-app-primary, 50);
}

mat-card.alert-warning {
    background-color: mat.get-color-from-palette($ide-app-warn, 100);
}

/* We don't want to have background colours on loads of components */
.mat-table, .mat-paginator {
    background: unset;
}