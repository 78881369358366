.admin {
    /* Typical height: Almost full screen */
    textarea.full-height, json-editor.full-height ace-editor {
        height: 95vh;
    }

    /* Typical height: Almost full screen, but inside a tab group */
    mat-tab-group textarea.full-height, mat-tab-group json-editor.full-height ace-editor {
        height: calc(95vh - 49px); /* Subtract height of tab group */
    }

    /* Typical height: Half a screen */
    textarea.half-height, json-editor.half-height ace-editor {
        height: 50vh;
    }

    /*Typical width: Full with of the screen, or es much width as the textarea can get*/
    textarea.full-width, json-editor.full-width ace-editor {
        width: 100%;
    }

    /* Make the ace-editor look a little like the surrounding components */
    ace-editor {
        min-height: 200px;
        width:100%;
        overflow: auto;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
    }

    /* Bootstrap aligns texts in the center, this looks odd for the parameter names */
    edit-input-parameter-value .input-group-text {
        text-align: left;
    }

    /* Some minor optical grouping of items in every trait scope */
    edit-single-trait-scope {
        display: block;
        padding: 5px;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        margin-bottom: 5px;
    }

    /* Chip lists prefer to stack their items vertically, lets only do this if there
       really is not enough space. */
    .full-width-chip-list {
        width: 100%;
    }

    /* Clickable root classes that have been added in the block language editor */
    .block-lang-css-root-class {
        cursor: pointer;
    }

    .block-lang-css-root-class:hover {
        background-color: red;
    }

    #news {
        tbody tr { cursor: pointer; }
        table {
            word-wrap: break-word;
            table-layout: fixed;
        }
        .row { margin: 1vh 0 1vh 0;}
        label { margin-top: 1vh; }
        input { margin-bottom: 1vh; }
        button { margin-top: 4vh; }
    }

    #edit-news {
        textarea { margin-top: 1vh }
        select { margin-bottom: 2vh; }
    }

    change-roles {
        display: flex;
        flex-direction: column;

        label {
            width: 20vw;
            min-width: 230px;
        }

        button {
            width: 230px;
        }
    }
}
