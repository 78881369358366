editor-block-host {

    editor-block-container {
        display: flex;
        &.horizontal {
            flex-direction: row;
            align-items: baseline;
        }
        &.vertical {
            flex-direction: column;
            align-items: flex-start; // Otherwise children occupy full width
        }

        &.allow-wrap {
            flex-wrap: wrap;
        }
    }

    .indent  {
        margin-left: 2ch;
    }

    .space-around {
        &::after, &::before {
            content: "\00a0 ";
        }
    }

    .space-after{
        &::after {
            content: "\00a0 ";
        }
    }

    .space-before{
        &::before {
            content: "\00a0 ";
        }
    }

    // Enquote something, as this also sets the "content" property it
    // may conflict with "space-before" and "space-after"
    .double-quote {
        &::after, &::before {
            content: "\"";
        }

        &.space-after{
            &::after {
                content: "\"\00a0 ";
            }
        }

        &.space-before{
            &::before {
                content: "\00a0 \"";
            }
        }
    }

    &.activate-block-outline editor-block-render-block {
        border: 1px solid black;
        border-radius: 5px;
        //margin: 2px;
        //padding: 2px;

        margin: 2px 5px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    &.activate-block-outline.activate-left-aligned-blocks editor-block-render-block {
        margin-left: -1px;
        margin-right: 1px;
    }

    // Selectively deactivate wrapping in constants, useful for
    // logically related terminal symbols like "else if".
    &.activate-nowrap span.no-wrap {
        white-space: nowrap;
    }

    // Mark something as a keyword of a language
    &.activate-keyword span.keyword, &.activate-keyword span.keyword select {
        color: blue;
    }

    // Mark something as a comment in a language
    &.activate-comment *.comment {
        color: green;
    }
}