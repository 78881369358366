/* Any editor that works with an AST */
.ast {
    /* The "hey, you should start programming by dragging something here"-notice */
    .empty-tree {
        border: 4px dashed blue;
        border-radius: 20px;
        min-height: 200px;
        padding: 15px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;


        h3, p {
            text-align: center;
        }

        p {
            margin: 0px;
        }
    }
}

/* An icon of a trashbin that hovers over anything and may be used to remove blocks */
trash {
    font-size: 6rem;
    position: fixed;
    padding: 5px;
    right: 350px;
    top: 45px;
    z-index: 2; /* 1 is not elevated enough, it conflicts with the material sidebar */
}

/* A virtual block that is dragged over the scene. */
.dragged {
    editor-block-render-block {
        background: white;
    }
}

/* The "block" AST editor */
editor-block-host {
    /* Default font settings */
    font-family: $monospace-font;
    font-size: 1.2rem;

    /* This component is purely virtual and must be disregarded with respect
       to e.g. flex-box layouts. */
    editor-block-render {
        display: contents;
    }

    /* Don't put drop targets exclusively on their own line */
    editor-block-render-drop-target {
        display: inline-block;
    }

    /* An actual block being rendered */
    editor-block-render-block {
        display: inline-block;   // To have "proper" rectangular borders / drag zones
        position: relative;      // To serve as an anchor for the more exact drop locations
        cursor: grab;

        /* A block that is readonly */
        .read-only {
            background-color: #efefef;
            cursor: unset;
        }

        .being-replaced {
            background-color: red;
        }

        /* Indicators where to drop stuff relative to the current block, this
           is for a vertical layout where the indicators are displayed at top or bottom */
        &.vertical > {

            .begin-drop, .end-drop, .begin-drop-zone, .end-drop-zone {
                position: absolute;
                left: 0px;
                right: 0px;
            }

            // Drop before this block
            .begin-drop, .begin-drop-zone {
                top: 0px;
                height: $relative-drop-hint-size;
            }

            .begin-drop {
                background: linear-gradient(
                    to top,
                    rgba(255,255,255,0) 10%,
                    rgba(130,130,130,0) 50%,
                    rgba(0,0,0,0.65) 100%
                );
            }

            // Drop after this block
            .end-drop, .end-drop-zone {
                bottom: 0px;
                height: $relative-drop-hint-size;
            }

            .end-drop {
                background: linear-gradient(
                    to bottom,
                    rgba(255,255,255,0) 10%,
                    rgba(130,130,130,0) 50%,
                    rgba(0,0,0,0.65) 100%
                );
            }
        }

        // Horizontal (left to right) indicators
        &.horizontal > {

            .begin-drop, .end-drop, .begin-drop-zone, .end-drop-zone {
                position: absolute;
                top: 0px;
                bottom: 0px;
            }

            // Drop before this block
            .begin-drop, .begin-drop-zone {
                left: 0px;
                width: $relative-drop-hint-size;
            }

            .begin-drop:hover {
                background: linear-gradient(
                    to left,
                    rgba(255,255,255,0) 10%,
                    rgba(130,130,130,0) 50%,
                    rgba(0,0,0,0.65) 100%
                );
            }

            // Drop after this block
            .end-drop, .end-drop-zone {
                right: 0px;
                width: $relative-drop-hint-size;
            }

            .end-drop:hover {
                background: linear-gradient(
                    to right,
                    rgba(255,255,255,0) 10%,
                    rgba(130,130,130,0) 50%,
                    rgba(0,0,0,0.65) 100%
                );
            }
        }
    }

    editor-block-render-input {
        .not-editing > * > .display-value {
            display: inline-flex;
            border-bottom: 1px dashed black;
            min-height: 1ch;
            min-width: 1ch;
        }

        // Input fields for users need to somehow "blend in" with the rest of the
        // blocks but also be recognizable.
        input {
            display: inline-flex;
            border: 0px;
            border-bottom: 1px dashed black;
            background-color: transparent;
        }

        // <select> also neds to blend in
        select {
            appearance: none;
            border: none;
            background-color: transparent;
            border-bottom: 1px dashed black;
            text-align: center;
            // Try to make this as close to a "normal" div as possible,
            // without these instructions the height will be slightly off.
            padding: 0;
            margin: 0;
        }
    }

    .break-after {
        width: 100%;
    }
}

/* This class is applied to the error tooltip itself */
.error-marker-tooltip {
    background-color: #b71c1c;
}

draggable-block-list, sidebar-item-host {
    ul {
        list-style-type: none;
    }

    .block {
        font-family: $monospace-font;
        cursor: default;
        font-size: 1.2rem;
        border: 2px solid black;
        white-space: nowrap;
        padding-left: 1em;
        padding-right: 1em;
        margin-bottom: 10px;
    }

    .rounded {
        border-radius: 500px;
    }

    /* Ensure that there is no unintended break for headings */
    summary {
        h1, h2, h3, h4, h5, h6 {
            display: inline;
        }
    }
}
