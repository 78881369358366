@use '@angular/material' as mat;
#front-nav {
    padding-top: 0px; /* Angular Material defines some padding that we dont want */
}

.front {
    padding-top: $toolbar-margin-bottom;

    .project-list-item h2 {
        font-weight: bold;
    }

    .project-list-item .card-img-top {
        opacity: 0.1;
        transition: opacity 0.3s;
    }

    .project-list-item:hover .card-img-top {
        opacity: 1;
        transition: opacity 0.3s;
    }

    .project-list-item .card-img-overlay {
        opacity: 1;
        transition: opacity 0.3s;
    }

    .project-list-item:hover .card-img-overlay {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s;
    }

    .target-groups {
        .mat-card {
            height: 100%;

            .mat-card-content {
                min-height: 170px;
                margin-bottom: unset;
            }
        }
    }

    .about-text {
        hyphens: auto;
        text-align: justify;
    }

    /* The carousel-like thing on the main page */
    .jumbotron {
        h1 img {
            height: 1.2em;
        }

        a.mat-button-large {
            margin-bottom: 10px;
        }
    }

    mat-card > mat-card-header > img.news-avatar {
        object-fit: contain;
    }

    /* News items */
    .news {
        .video-centered {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }

    /* Information about academical projects */
    .academia-item {
        margin-bottom: 1rem;

        .mat-card-title {
            line-height: 1em;
        }

        &:target {
            background-color: mat.get-color-from-palette($ide-app-accent, 200);
        }

        .institution-logo {
            float: right;
        }

        p {
            text-align: justify;
            hyphens: auto;
        }
    }
}

#error-container {
    margin-top: 50px;
}
