.editor-images {
    .filter-input {
        width: 25%;
        min-width: 12rem;
        display: inline-block;

    }

    .image-card {
        min-height: 15.35rem;
        min-width: 16rem;
    }

    .card:hover > .card-img-overlay {
        display: block;
    }

    .card > .card-img-overlay {
        display: none;
    }

    .table-icon-col {
        width: 128px;
    }

    .table-icon {
        max-width: 128px;
        max-height: 128px;
    }

    .table-button {
        width: 9rem;
        display: block
    }
}

/* Not nested into any class because it is meant to be used anywhere */
image-selector {
    display: flex;

    img {
        max-width: calc(2.25rem + 2px);
        max-height: calc(2.25rem + 2px);
        margin: 2px;
    }
}
