$button-size: 14rem;

$provider-button-heigth: 45px;
$provider-button-width: 250px;

$margin_of_validate_inputs: 10px;

$font-size-links: 0.8em;

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authentication {
  h1 {
    font-size: 1.5rem;
  }

  .form-group {
    width: 100%;
  }

  hr {
    margin: 2em 0 2em 0;
    background-color: rgba($color: #a1a1a1, $alpha: 0.5);
  }

  .mat-raised-button {
    width: $button-size;
    margin-bottom: 0.5em;
  }

  a {
    color: rgba($color: #1f54a5, $alpha: 1.0) !important;
    cursor: pointer;
  }

  .reset-password-link-text {
    font-size: $font-size-links;
    margin-top: 2em;
  }

  .resend-activation-link-text {
    font-size: $font-size-links;
    margin-top: 2em;
  }

  .input-group {
    margin-top: $margin_of_validate_inputs;
    width: $button-size;
  }

  provider-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mat-raised-button {
      color: white !important;
      width: $provider-button-width;
      height: $provider-button-heigth;
    }
  }
}

request-reset-password, request-verify-email {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $provider-button-width;

  .mat-raised-button {
    width: $button-size;
  }

  validate-input {
    display: flex;
    justify-content: center;

    width: 100%;
    margin-bottom: $margin_of_validate_inputs;
  }
}