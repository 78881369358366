/* Toolbar dimensions */
$toolbar-padding: 5px;
$toolbar-height: 44px;
$toolbar-margin-bottom: 5px;

/* Editor layout */
$sidebar-width:  230px;
$navbar-width:   230px;

/* Width / height of relative drop locator indicator */
$relative-drop-hint-size: 15px;

$monospace-font: "Roboto Mono", monospace;
