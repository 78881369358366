@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light '),
    url('../../../node_modules/typeface-roboto/files/roboto-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../node_modules/typeface-roboto/files/roboto-latin-300.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Light '),
    url('../../../node_modules/typeface-roboto/files/roboto-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../node_modules/typeface-roboto/files/roboto-latin-400.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Light '),
    url('../../../node_modules/typeface-roboto/files/roboto-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../node_modules/typeface-roboto/files/roboto-latin-500.woff') format('woff'); /* Modern Browsers */
}


@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Mono'),
    url('../../../node_modules/typeface-roboto-mono/files/roboto-mono-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../node_modules/typeface-roboto-mono/files/roboto-mono-latin-500.woff') format('woff'); /* Modern Browsers */
}
