/* Visual enhancements of the displayed schema */
.db-schema-display {
    svg {
        /* Shrink schemas that are too big*/
        max-width: 100%;

        .active {
            transition: stroke-width 1.5s;
        }

        .active.node {
            stroke-width: 5px;
        }
        .active.edge {
            stroke-width: 10px;
        }

        /* Array.from(document.querySelector(".db-schema-display > svg:nth-child(1)").querySelectorAll(".aufgabe")).forEach(elem => elem.classList.add("active")) */
    }
}