#st-card {
    max-height: 55vh;
    min-width: 20em;
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-block;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

  &> p {
    text-align: center;
  }

  .st-container {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: 90% 10%;
  }

  .st-container p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    position: relative;
    min-height: 2.5rem;
  }

   li p {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

   li i {
    margin-top: 0.45rem;
    margin-bottom: 0.25rem;
  }

  mat-progress-bar {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
//Forcing the mat-menu-panel to take a max-width greater than 280px
.st-panel {max-width: 20rem !important;}