.schema-sidebar {
    margin-bottom: 10px;
}

.schema-sidebar .stack {
  display: flex;
  flex-direction: column;
}

.schema-sidebar .item {
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 10px;
    cursor: pointer;
}

.schema-table-header {
    display: flex;

    a {
        text-decoration: none;
    }
    
    > *:first-child {
        font-size: 1.5rem;
        flex-grow: 1;
    }
}

.schema-mapping-sign {
    font-size: 2em; 
    line-height: 0; 
}