.editor {
    .navbar {
        width: $navbar-width;
        flex: 1;
    }

    .sidebar {
        flex: 1;
        width: $sidebar-width;
    }

    /* A little spacing away from the navbar */
    .content {
        padding-top: 10px;
    }

    /* Navigation inside a project, typically on the left hand side */
    .navbar {
        editor-navbar {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            nav {
                margin: 5px;



                /**
                 * Disable the usual bullet points
                 */
                ul {
                    list-style: none;
                }

                /* First level list needs no padding, this is overridden for nested lists. */
                ul {
                    padding-left: 0px;
                }

                /* Nested lists get a little indentation */
                ul ul {
                    padding-left: 5px;
                }
            }

            .sources-host {
                margin-top: auto;

                * {
                    hyphens: auto;
                }
            }

            a.indent {
                padding-left: 15px;
            }

            .nav-query, .nav-page, .nav-database, .nav-image, .nav-code-resource {
                margin-left: 15px;
            }
        }
    }
}

.sidebar textarea {
    resize: vertical;
}

/**
 * A list of theoretically arbitrary column-items.
 */
.column-list {
    display: flex;
    flex-wrap: wrap;
}
